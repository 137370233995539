import Image from "next/image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Heading from "../typography/heading";
import ImageModal from "../modal/image-modal";
import { Projects } from "@app/config/project";

const Project = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [project, setProject] = React.useState("");

  const handleToggle = () => {
    setModalShow(!modalShow);
  };

  const onClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <Heading
        Head1="OUR"
        Head2="GALLERY"
        para="Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla mollis dapibus nunc, ut rhoncus turpis sodales quis. Integer sit amet mattis quam."
      />
      <div className="contair">
        <Row className="R-pro">
          {Projects.length > 0 &&
            Projects.map((project, index) => (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                className="C-pro"
                key={index}
              >
                {/* // */}
                <Image
                  src={project.imageUrl}
                  className="image img-fluid"
                  priority
                  alt="p01"
                  onClick={() => {
                    handleToggle();
                    setProject(project);
                  }}
                />
              </Col>
            ))}
        </Row>
      </div>
      <ImageModal
        show={modalShow}
        handleToggle={handleToggle}
        imageUrl={project}
        onClose={onClose}
      />
    </>
  );
};

export default Project;
