import { Button, Card, Col, Row } from "react-bootstrap";
import Image from "next/image";

const AboutOurCompany = (props) => {
  return (
    <div className="mian">
      <Row className="row-m">
        <Col xs={6} className="col-m">
          <div className="content">
            <Card.Body>
              <h6 className="title">{props.title1}</h6>
              <h3>
                {props.heading}
              </h3>
              <Card.Text className="para">
                {props.para}
              </Card.Text>
            </Card.Body>
          </div>
          <div className="content">
            <Image src={props.imageUrl} alt="aboutImg" className="img-fluid" priority  />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutOurCompany;
