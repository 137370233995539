import AboutOurCompany from "@app/components/aboutComp/index";
import List from "@app/components/accordian/list";
import ComponentCarouel from "@app/components/componentsCarousel";
import Counter from "@app/components/counter";
import IndustryCorporation from "@app/components/industrycorporatoin";
import Project from "@app/components/projects";
import HomeSlider from "@app/components/slidercontent/homeslider";
import HomeHeroSlider from "@app/modules/home-hero-slider";
import aboutImg from "@public/images/ias-images/image_05.webp";
import svg3 from "@public/images/band.png";
import svg2 from "@public/images/group.png";
import aspactImg02 from "@public/images/ias-images/image_19.webp";
import aspactImg01 from "@public/images/ias-images/image_23.webp";
import aspactImg03 from "@public/images/ias-images/image_44.webp";
import image1 from "@public/images/ic.jpg";
import svg1 from "@public/images/laptop.png";
import svg4 from "@public/images/smile.png";
import Image from "next/image";
import { SwiperSlide } from "swiper/react";

const HomeScreen = () => {
  
  return (
    <>
      <HomeHeroSlider />
      <AboutOurCompany
        title1="ABOUT IAS"
        heading="Empowering Industries Through Innovative Automation Solutions"
        para="At Industrial Automation and Solutions (IAS), we are
                dedicated to revolutionizing the industrial landscape by
                providing cutting-edge automation services and tailored
                solutions. With a commitment to efficiency, reliability, and
                technological excellence, we bring a new era of productivity to
                our clients in various industries."
        imageUrl={aboutImg}
      />
      <HomeSlider />
      <IndustryCorporation imageUrl={image1} titleH="What Sets Us Apart">
        <div className="about-slider">
          <List
            span="Expertise:"
            para="Our team of skilled professionals brings extensive expertise in
          industrial automation, ensuring that your processes are optimized for peak
          performance."
          titleH="What Sets Us Apart"
          />
          <List
            span="Custom Solutions:"
            para="We understand that every industry is unique. That's why
          we offer customized automation solutions designed to meet the specific needs
          and challenges of your business."
          />
          <List
            span="Innovation:"
            para="Staying at the forefront of technological advancements, we
          integrate the latest automation technologies to enhance your operational
          efficiency and drive sustainable growth."
          />
        </div>
        <div className="about-slider">
          <ComponentCarouel>
            <SwiperSlide>
              <Image
                src={aspactImg01}
                alt="slide01"
                className="img-fluid"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src={aspactImg02}
                alt="slide01"
                className="img-fluid"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src={aspactImg03}
                alt="slide01"
                className="img-fluid"
              />
            </SwiperSlide>
          </ComponentCarouel>
        </div>
      </IndustryCorporation>
      <Project />
      <div className="home-counter">
        <Counter value={80} icon={svg1} />
        <Counter value={100} icon={svg2} />
        <Counter value={90} icon={svg3} />
        <Counter value={120} icon={svg4} />
      </div>
    </>
  );
};

export default HomeScreen;
