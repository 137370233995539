import { Carousel } from "react-bootstrap";

const HomeHeroSlider = () => {
  return (
    <section className="banner-section">
      <Carousel>
        <Carousel.Item className="slide-img img-fluid"></Carousel.Item>
        <Carousel.Item className="slide-img02 img-fluid"></Carousel.Item>
        <Carousel.Item className="slide-img03 img-fluid"></Carousel.Item>
        <Carousel.Item className="slide-img04 img-fluid"></Carousel.Item>
        <Carousel.Item className="slide-img05 img-fluid"></Carousel.Item>
        <Carousel.Item className="slide-img06 img-fluid"></Carousel.Item>
      </Carousel>
    </section>
  );
};
export default HomeHeroSlider;
