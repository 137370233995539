import CardSlider from '@app/components/cardslider';
import BlogModal from '@app/components/modal/blogmodal';
import { HomeBlogs } from '@app/config/homeBlog';
import Image from 'next/image';
import { useState } from 'react';
import { SwiperSlide } from 'swiper/react';

const HomeSlider = () => {
    //   const [modalShow, setModalShow] = React.useState(false);
  const [showIndex, setShowIndex] = useState(null);
  const handleShow = (index) => {
    setShowIndex(index === showIndex ? null : index);
  }
  return (
    <>
    <CardSlider>
         {HomeBlogs.length > 0 &&
           HomeBlogs.map((service, index) => {
             return (
               <SwiperSlide key={index}>
                 <div className="card" >
                   <div className="card-image">
                     <Image
                       src={service.img}
                       alt="cm"
                       priority
                       className="img-fluid"
                     />
                   </div>
                   <div className="card-content">
                     <h4 className="my-3">{service.heading} </h4>
                     <p>{service.description}</p>
                     <button
                       className="read-more"
                       onClick={() => handleShow(index)}
                     >
                       Read More
                     </button>
                   </div>
                 </div>
                   {showIndex === index && (
                    <BlogModal
                    show={true}
                    handleShow={() => handleShow(index)}
                    fullscreen
                    blog={service}
                    index={index}
                  />
                   )}
               </SwiperSlide>
             );
           })}
       </CardSlider>
   </>
  )
}

export default HomeSlider;