import React from "react";

const Heading = (props, { className }) => {
  return (
    <div className="heading">
      <h6> {props.Head1} </h6>
      <h3 className="head3"> {props.Head2} </h3>
      <p>{props.para}</p>
    </div>
  );
};

export default Heading;
