// Import Swiper React components
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";

const CardSlider = (props) => {
  return (
    <>
      <Swiper
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
          },
          970: {
            slidesPerView: 3,
          },
          1500: {
            slidesPerView: 4,
          },
        }}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
        style={{ width: "95%" }}
      >
        {props.children}
      </Swiper>
    </>
  );
};
export default CardSlider;
