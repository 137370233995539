import React from "react";
import Modal from "react-bootstrap/Modal";
import Image from "next/image"
import ContactModal from "../contactmodal";

const BlogModal = ({ show, handleShow, fullscreen, blog, index }) => {
  return (
    <React.Fragment key={index}>
      <Modal
        show={show}
        fullscreen={fullscreen}
        key={index}
        onHide={() => {
          handleShow(index);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{blog.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={blog.img} alt="img" className="img-fluid" priority/>
          <p><span>{blog.title01}</span>{blog.para01}</p>
          <p><span>{blog.title02}</span>{blog.para02}</p>
          <p><span>{blog.title03}</span>{blog.para03}</p>
          <p><span>{blog.title04}</span>{blog.para04}</p>
          <p><span>{blog.title05}</span>{blog.para05}</p>
          <p><span>{blog.title06}</span>{blog.para06}</p>
          <p><span>{blog.title07}</span>{blog.para07}</p>
          <p><span>{blog.title08}</span>{blog.para08}</p>
          <p><span>{blog.title09}</span>{blog.para09}</p>
          <p><span>{blog.title10}</span>{blog.para10}</p>
          <p><span>{blog.title11}</span>{blog.para11}</p>
          <p><span>{blog.title12}</span>{blog.para12}</p>
          <p><span>{blog.title13}</span>{blog.para13}</p>
          <p><span>{blog.title14}</span>{blog.para14}</p>
        <ContactModal />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default BlogModal;
