
const { Row, Col, Card } = require("react-bootstrap");

const IndustryCorporation = (props) => {
  return (
    <div className="maini">
      <Row className={`${props.className ? props.className : "row-i"}`}>
        <Col className="col-i">
              {/* <h6 className="title">{props.titleH}</h6> */}
          <div className="content">
            {props.children}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default IndustryCorporation;
