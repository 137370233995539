import { ListGroup } from "react-bootstrap";
import tick from "@public/images/checked.png";
import Image from "next/image";

const List = (props) => {
  return (
    <>
      <ListGroup className="listg">
      <h6 className="title">{props.titleH}</h6>
        <ListGroup.Item as="li" className="listi">
          <Image src={tick} alt="tick" />
          <p><span>{props.span}</span>{props.para}</p>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default List;
