import Project01 from "@public/images/ias-images/image_56.webp";
import Project02 from "@public/images/P02.webp";
import Project04 from "@public/images/ias-images/image_50.webp";
import Project05 from "@public/images/ias-images/image_57.webp";
import Project06 from "@public/images/ias-images/image_25.webp";
import Project03 from "@public/images/P07.webp";

export const Projects = [
  {
    imageUrl: Project01,
  },
  {
    imageUrl: Project02,
  },
  {
    imageUrl: Project03,
  },
  {
    imageUrl: Project04,
  },
  {
    imageUrl: Project05,
  },
  {
    imageUrl: Project06,
  },
];
