import { Col, Row } from "react-bootstrap";

import { useEffect, useState } from "react";
import Image from "next/image";
const Counter = ({ value, icon }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + 1;
        return newCount <= value ? newCount : prevCount;
      });
    }, [50]); // Update the count every second (1000 milliseconds)
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  },[]); // Empty dependency array ensures the effect runs only once on mount
  return (
    <div className="cm">
      <Row className="rowcm">
        <Col sm className="colcm">
          <Image src={icon} alt="img" className="img-fluid" priority={true} width={50}/>
          <p>{count}</p>
          <h4>Projects & Residentials Completed In 2020</h4>
        </Col>
      </Row>
    </div>
  );
};
export default Counter;
